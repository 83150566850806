// src/components/ContactUs.js
import React from 'react';
import './ContactUs.css'; // Import the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

function ContactUs() {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Add logic to handle the form submission (e.g., send an email)
  };

  return (
    <div className="contact-us">
      <h2>Contact Us</h2>
      <p>
        Have questions or need assistance? Contact our team for prompt and friendly support.
      </p>
      <form className="contact-form" onSubmit={handleSubmit}>
        {/* Form fields go here */}
        <label>
          Name:
          <input type="text" name="name" required />
        </label>
        <label>
          Email:
          <input type="email" name="email" required />
        </label>
        <label>
          Message:
          <textarea name="message" rows="4" required />
        </label>
        <button type="submit">Submit</button>
      </form>
      <div className="social-icons">
        <h3>Connect with Us:</h3>
        <a href="https://www.facebook.com/officialsalmuftah" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} className="icon" />
        </a>
        <a href="https://www.twitter.com/salmuftahtravel" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitter} className="icon" />
        </a>
        <a href="https://www.instagram.com/officialsalmuftah" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} className="icon" />
        </a>
        <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} className="icon" />
        </a>
      </div>
    </div>
  );
}

export default ContactUs;
