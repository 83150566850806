import React, { useState } from 'react';
import './CustomerPassportIssuing.css';
import { addDoc, collection } from 'firebase/firestore';
import { db, storage } from '../components/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

function CustomerPassportIssuing() {
  const [formData, setFormData] = useState({
    fullName: '',
    passportNumber: '',
    issueDate: '',
    applicationType: 'new',
    bioData: '',
    nin: '',
    dateOfBirth: '',
    phoneNumber: '',
    address: '',
    nextOfKinBioData: '',
    nextOfKinAddress: '',
    profilePicture: null,
    proofOfIndigene: null,
    dateOfBirthCertificate: null,
    renewalNIN: '',
    renewalDateOfBirth: '',
  });

  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Function to handle file upload
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  // Function to handle file upload to Firebase Storage
  const handleFileUpload = async (file) => {
    const storageRef = ref(storage, 'uploadedFiles/' + file.name);
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    return url;
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Upload files and get URLs
      const profilePictureUrl = formData.profilePicture
        ? await handleFileUpload(formData.profilePicture)
        : '';
      const proofOfIndigeneUrl = formData.proofOfIndigene
        ? await handleFileUpload(formData.proofOfIndigene)
        : '';
      const dateOfBirthCertificateUrl = formData.dateOfBirthCertificate
        ? await handleFileUpload(formData.dateOfBirthCertificate)
        : '';

      // Add a new document to the 'passportIssuingRequests' collection
      await addDoc(collection(db, 'passportIssuingRequests'), {
        ...formData,
        profilePicture: profilePictureUrl,
        proofOfIndigene: proofOfIndigeneUrl,
        dateOfBirthCertificate: dateOfBirthCertificateUrl,
      });

      // Send email request to the backend server
      const response = await fetch('http://localhost:5000/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          profilePictureUrl,
          proofOfIndigeneUrl,
          dateOfBirthCertificateUrl,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to send email');
      }

      console.log('Email sent successfully.');

      // Optionally, show success message to the user
      alert('Your passport request has been submitted successfully!');

      // Reset the form data
      setFormData({
        fullName: '',
        passportNumber: '',
        issueDate: '',
        applicationType: 'new',
        bioData: '',
        nin: '',
        dateOfBirth: '',
        phoneNumber: '',
        address: '',
        nextOfKinBioData: '',
        nextOfKinAddress: '',
        profilePicture: null,
        proofOfIndigene: null,
        dateOfBirthCertificate: null,
        renewalNIN: '',
        renewalDateOfBirth: '',
      });
    } catch (error) {
      console.error('Error adding document: ', error);
      alert(
        'There was an error submitting your passport request. Please try again later.'
      );
    }
  };

  return (
    <div className="customer-passport-issuing-page">
      <h2>Customer Passport Issuing</h2>
      <p>
        Welcome to the Customer Passport Issuing page. Please fill out the form
        below to request passport issuing services.
      </p>
      <form onSubmit={handleSubmit}>
        <label>
          Application Type:
          <select
            name="applicationType"
            value={formData.applicationType}
            onChange={handleInputChange}
            required
          >
            <option value="new">New Passport</option>
            <option value="renewal">Passport Renewal</option>
          </select>
        </label>
        <label>
          Full Name:
          <input
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleInputChange}
            required
          />
        </label>
        <label>
          Passport Number:
          <input
            type="text"
            name="passportNumber"
            value={formData.passportNumber}
            onChange={handleInputChange}
            required
          />
        </label>
        <label>
          Issue Date:
          <input
            type="date"
            name="issueDate"
            value={formData.issueDate}
            onChange={handleInputChange}
            required
          />
        </label>

        {/* New Passport Fields */}
        {formData.applicationType === 'new' && (
          <>
            <label>
              Bio Data:
              <input
                type="text"
                name="bioData"
                value={formData.bioData}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              NIN:
              <input
                type="text"
                name="nin"
                value={formData.nin}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Date of Birth:
              <input
                type="date"
                name="dateOfBirth"
                value={formData.dateOfBirth}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Phone Number:
              <input
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Address:
              <textarea
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Next of Kin Bio Data:
              <input
                type="text"
                name="nextOfKinBioData"
                value={formData.nextOfKinBioData}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Next of Kin Address:
              <textarea
                name="nextOfKinAddress"
                value={formData.nextOfKinAddress}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Upload Profile Picture:
              <input
                type="file"
                name="profilePicture"
                accept="image/*"
                onChange={handleFileChange}
                required
              />
            </label>
            <label>
              Upload Proof of Indigene:
              <input
                type="file"
                name="proofOfIndigene"
                accept=".pdf, .jpg, .jpeg, .png"
                onChange={handleFileChange}
                required
              />
            </label>
            <label>
              Upload Date of Birth Certificate:
              <input
                type="file"
                name="dateOfBirthCertificate"
                accept=".pdf, .jpg, .jpeg, .png"
                onChange={handleFileChange}
                required
              />
            </label>
          </>
        )}

        {/* Renewal Fields */}
        {formData.applicationType === 'renewal' && (
          <>
            <label>
              NIN:
              <input
                type="text"
                name="renewalNIN"
                value={formData.renewalNIN}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Date of Birth:
              <input
                type="date"
                name="renewalDateOfBirth"
                value={formData.renewalDateOfBirth}
                onChange={handleInputChange}
                required
              />
            </label>
          </>
        )}

        <button type="submit">Submit Request</button>
      </form>
    </div>
  );
}

export default CustomerPassportIssuing;
