// src/components/AboutUsPage.js
import React from 'react';
import './AboutUsPage.css'; // Create a CSS file for styling

function AboutUsPage() {
  return (
    <div className="about-us-page">
      <header className="header">
        <h1>About Sal Muftah Investment Limited</h1>
      </header>
      <section className="main-content">
        <p>
          Sal Muftah Investment Limited is a highly specialized company offering a wide range of high-quality
          multi-disciplinary services from the initial brief to full commission. We are registered with the Corporate
          Affairs Commission (CAC) in the Federal Republic of Nigeria, with our head office located at No. 5, John F.
          Kennedy Street, Asokoro, Abuja-Nigeria, and branch offices at Suite B1, B2, Zara City Plaza, Lagos
          Street-Maiduguri, and other states.
        </p>
        <p>
          We provide a multi-disciplinary world-class service, and our skills are diverse, allowing us to assess and
          deliver realistic projects and services. With a diverse clientele, we offer our services individually or as a
          cohesive package, delivering rich solutions to help our customers meet their core business obligations or
          services.
        </p>
        <p>
          In an ever-changing business or commercial environment, success comes to those who make or create the
          difference. All our teams have substantial expertise laced with years of experience working with a range of
          business environments across all types of companies.
        </p>
        <p>
          We apply innovation, fresh thinking, and principles in all we do, resulting in the production of original
          solutions that address our customers' business needs. We add value by providing the extra resources required,
          hinged on consistency of services rendered, flexibility, and rapid response.
        </p>
        <h2>Our Belief</h2>
        <p>
          The greatest reward comes to those who foresee and act upon an opportunity; and in doing so change the dynamics
          of the system for a better future.
        </p>
        <h2>Our Vision</h2>
        <p>
          Our vision is to always effect a positive change in the system and business arena, to bring prosperity and
          enhance the quality of life.
        </p>
        <h2>Mission</h2>
        <p>
          We aim to maximize our collective potentials by relentlessly pursuing business innovations and opportunities in
          niche, transitional, and emerging markets.
        </p>
      </section>
    </div>
  );
}

export default AboutUsPage;
