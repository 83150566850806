import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import i18n from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAOfmv07kBU-1yMnNX7F-qNQ4zwCA3C9JI",
  authDomain: "salmuftah-investments.firebaseapp.com",
  projectId: "salmuftah-investments",
  storageBucket: "salmuftah-investments.appspot.com",
  messagingSenderId: "460215161771",
  appId: "1:460215161771:web:55cf0cb63e8669095ff8ee"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);// eslint-disable-next-line no-unused-vars
const analytics = getAnalytics(app);

// Use Firebase features here if needed
// Example: const auth = getAuth(app);

// i18n configuration...
i18n.init({
  resources: {
    en: {
      translation: {
        'Welcome to React': 'Welcome to React and react-i18next',
      },
    },
  },
  lng: document.querySelector('html').lang || 'en',
  fallbackLng: 'en',
});

// React root creation...
const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
