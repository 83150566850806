// ServiceCard.js
import React from 'react';
import './ServiceCard.css';

function ServiceCard({ title, description, imageUrl }) {
  return (
    <div className="service-card">
      <img src={imageUrl} alt={title} className="service-image" />
      <div className="service-info">
        <h3 className="service-title">{title}</h3>
        <p className="service-description">{description}</p>
      </div>
    </div>
  );
}

export default ServiceCard;
