import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAOfmv07kBU-1yMnNX7F-qNQ4zwCA3C9JI",
  authDomain: "salmuftah-investments.firebaseapp.com",
  projectId: "salmuftah-investments",
  storageBucket: "salmuftah-investments.appspot.com",
  messagingSenderId: "460215161771",
  appId: "1:460215161771:web:55cf0cb63e8669095ff8ee"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export { app, db, storage };
