// src/components/Footer.js
import React from 'react';
import './Footer.css'; // Import the CSS file for styling

function Footer() {
  return (
    <footer className="main-footer">
      <div className="footer-content">
        {/* Copyright information */}
        <p>&copy; 2023 Salmuftah Investment & Travel Tours. All rights reserved.</p>

        {/* Additional information */}
        <div className="contact-info">
          <p className="address">HEAD OFFICE: 5, JOHN F. KENNEDY STREET, ASOKORO, ABUJA, NIGERIA</p>
          <p className="address">BRANCH OFFICE: SUITE B1, B2, ZARA CITY PLAZA, LAGOS STREET, MAIDUGURI, BORNO STATE, NIGERIA</p>
          <p className="hotlines">HOT LINES: 07037354709, 08050269126</p>
          <p className="emails">Email: <a href="mailto:salmuftah@yahoo.co.uk">salmuftah@yahoo.co.uk</a>, <a href="mailto:salmuftahtravels@yahoo.com">salmuftahtravels@yahoo.com</a></p>
        </div>

        {/* Additional element (disclaimer) */}
        <div className="disclaimer">
          <p>This website is for informational purposes only. For accurate and up-to-date information, please contact us directly.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
