// HomePage.js
import React from 'react';// eslint-disable-next-line 
import { Link } from 'react-router-dom';
import './HomePage.css';
import ServiceCard from './ServiceCard'; // Update the path if needed


function HomePage() {
  const services = [
    {
      title: 'Travel Consulting',
      description: 'Expert advice and consultation for your travel plans.' ,
      imageUrl: 'images/travel-consulting.jpeg',
    },
    {
      title: 'Special Umrah/Hajj Packages',
      description: 'Exclusive packages for Umrah and Hajj pilgrimages.',
      imageUrl: 'images/Hajj.jpg',
    },
    {
      title: 'E-Ticketing',
      description: 'Book domestic and international tickets with ease.',
      imageUrl: 'images/e-ticket.jpg',
    },
    {
      title: 'Visa Application',
      description: 'Assistance with visa application processes.',
      imageUrl: 'images/passport.jpg',
    },
    {
      title: 'Hotel Reservations & Booking',
      description: 'Secure your stay with our hotel reservation services.',
      imageUrl: 'images/hotel.jpg',
    },
    {
      title: 'Passport Application Assistance',
      description: 'Guidance and support for passport applications.',
      imageUrl: 'images/passp.jpg',
    },
    {
      title: 'Logistics Services',
      description: 'Efficient logistics solutions for your travel needs.',
      imageUrl: 'images/logistics.jpg',
    },
    {
      title: 'Graphics & Printing',
      description: 'High-quality graphics and printing services.',
      imageUrl: 'images/graphics.jpg',
    },
    {
      title: 'Information & Communication Technology',
      description: 'Cutting-edge IT solutions for your business.',
      imageUrl: 'images/ict.jpg',
    },
    {
      title: 'Contract Procurement',
      description: 'Facilitating contract procurement processes.',
      imageUrl: 'images/contract.jpg',
    },
    {
      title: 'General Contract & Supply',
      description: 'Comprehensive contract and supply services.',
      imageUrl: 'images/gn.jpg',
    },
  ];

  return (
    <div className="home-page">
      <header className="header">
        <h1>Welcome to Salmuftah Investment</h1>
      </header>
      
      <section className="main-content">
        <h2>Explore Amazing Travel Tours with Salmuftah Investment</h2>
        <p>
          Embark on a journey of a lifetime with Salmuftah Investment. Discover breathtaking destinations,
          experience world-class hospitality, and create unforgettable memories.
        </p>
        <div className="image-gallery">
          <img src="images/image1.jpeg" alt="Travel Destination 1" />
          <img src="images/image2.jpeg" alt="Travel Destination 2" />
          <img src="images/image3.jpeg" alt="Travel Destination 3" />
        </div>
        <p className="additional-info">
          Whether you are an adventure seeker, a culture enthusiast, or someone looking for a relaxing getaway,
          Salmuftah Investment has the perfect travel experience for you. Explore our tour packages and start
          planning your next adventure!
        </p>
        <center><h1>OUR SERVICES</h1></center>

        {/* Add service cards */}
        <div className="service-cards-container">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>
      </section>
    </div>
  );
}

export default HomePage;
