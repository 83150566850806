import React, { useState } from "react";
import "./CustomerTicketRequest.css";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../components/firebase"; // Adjust the path to your firebase configuration file
import Select from "react-select";
import { Country, State } from "country-state-city";

function CustomerTicketRequest() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [destination, setDestination] = useState("");
  const [departureFromCountry, setDepartureFromCountry] = useState("");
  const [departureFromState, setDepartureFromState] = useState("");
  const [departureToCountry, setDepartureToCountry] = useState("");
  const [departureToState, setDepartureToState] = useState("");
  const [departureDate, setDepartureDate] = useState("");
  const [returningDate, setReturningDate] = useState("");
  const [tripType, setTripType] = useState("one-way");
  const [numberOfTickets, setNumberOfTickets] = useState("");
  const [seatPreference, setSeatPreference] = useState("");
  const [mealPreference, setMealPreference] = useState("");
  const [ticketType, setTicketType] = useState("adult");
  const [comments, setComments] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Add a new document to the 'ticketRequests' collection using the imported 'db'
      const docRef = await addDoc(collection(db, "ticketRequests"), {
        name,
        email,
        destination,
        departureFromCountry,
        departureFromState,
        departureToCountry,
        departureToState,
        departureDate,
        returningDate: tripType === "return" ? returningDate : null,
        tripType,
        numberOfTickets,
        seatPreference,
        mealPreference,
        ticketType,
        comments,
      });

      console.log("Document written with ID: ", docRef.id);
    } catch (error) {
      console.error("Error adding document: ", error);
    }

    // Reset the form fields
    setName("");
    setEmail("");
    setDestination("");
    setDepartureFromCountry("");
    setDepartureFromState("");
    setDepartureToCountry("");
    setDepartureToState("");
    setDepartureDate("");
    setReturningDate("");
    setTripType("one-way");
    setNumberOfTickets("");
    setSeatPreference("");
    setMealPreference("");
    setTicketType("adult");
    setComments("");
  };

  // Get options for countries
  const countryOptions = Country.getAllCountries().map((country) => ({
    value: country.isoCode,
    label: country.name,
  }));

  // Get options for states based on selected country
  const getStateOptions = (countryCode) =>
    State.getStatesOfCountry(countryCode).map((state) => ({
      value: state.isoCode,
      label: state.name,
    }));

  return (
    <div className="ticket-request-form">
      <h2>Customer Ticket Request</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </label>
        <label>
          Email:
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </label>
        <label>
          Destination:
          <input
            type="text"
            value={destination}
            onChange={(e) => setDestination(e.target.value)}
            required
          />
        </label>
        <label>
          Departure From:
          <Select
            options={countryOptions}
            value={countryOptions.find(
              (option) => option.value === departureFromCountry
            )}
            onChange={(option) => setDepartureFromCountry(option.value)}
            placeholder="Select country"
          />
          <Select
            options={getStateOptions(departureFromCountry)}
            value={getStateOptions(departureFromCountry).find(
              (option) => option.value === departureFromState
            )}
            onChange={(option) => setDepartureFromState(option.value)}
            placeholder="Select state"
            isDisabled={!departureFromCountry}
          />
        </label>
        <label>
          Departure To:
          <Select
            options={countryOptions}
            value={countryOptions.find(
              (option) => option.value === departureToCountry
            )}
            onChange={(option) => setDepartureToCountry(option.value)}
            placeholder="Select country"
          />
          <Select
            options={getStateOptions(departureToCountry)}
            value={getStateOptions(departureToCountry).find(
              (option) => option.value === departureToState
            )}
            onChange={(option) => setDepartureToState(option.value)}
            placeholder="Select state"
            isDisabled={!departureToCountry}
          />
        </label>
        <label>
          Trip Type:
          <select
            value={tripType}
            onChange={(e) => setTripType(e.target.value)}
            required
          >
            <option value="one-way">One Way</option>
            <option value="return">Return</option>
          </select>
        </label>
        <label>
          Departure Date:
          <input
            type="date"
            value={departureDate}
            onChange={(e) => setDepartureDate(e.target.value)}
            required
          />
        </label>
        {tripType === "return" && (
          <label>
            Returning Date:
            <input
              type="date"
              value={returningDate}
              onChange={(e) => setReturningDate(e.target.value)}
              required
            />
          </label>
        )}
        <label>
          Number of Tickets:
          <input
            type="number"
            value={numberOfTickets}
            onChange={(e) => setNumberOfTickets(e.target.value)}
            required
          />
        </label>
        <label>
          Ticket Type:
          <select
            value={ticketType}
            onChange={(e) => setTicketType(e.target.value)}
            required
          >
            <option value="adult">Adult</option>
            <option value="child">Child</option>
            <option value="infant">Infant</option>
          </select>
        </label>
        <label>
          Seat Preference:
          <select
            value={seatPreference}
            onChange={(e) => setSeatPreference(e.target.value)}
            required
          >
            <option value="">Select</option>
            <option value="window">Window</option>
            <option value="aisle">Aisle</option>
          </select>
        </label>
        <label>
          Meal Preference:
          <div className="meal-preference-options">
            <label>
              <input
                type="radio"
                value="vegetarian"
                checked={mealPreference === "vegetarian"}
                onChange={() => setMealPreference("vegetarian")}
              />
              Vegetarian
            </label>
            <label>
              <input
                type="radio"
                value="non-vegetarian"
                checked={mealPreference === "non-vegetarian"}
                onChange={() => setMealPreference("non-vegetarian")}
              />
              Non-Vegetarian
            </label>
          </div>
        </label>
        <label>
          Comments:
          <textarea
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          />
        </label>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default CustomerTicketRequest;
