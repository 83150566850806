// src/components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css'; // Import the CSS file for styling

function Header() {
  return (
    <header className="main-header">
      <div className="logo-container">
        <img src="images/logo-SalMuftah-3.png" alt="Salmuftah Investment Logo" className="logo" />
       
      </div>
      <nav className="main-nav">
        <ul>
        <li><Link to="/">Home</Link></li>
  <li><Link to="/about-us">About Us</Link></li>
  <li><Link to="/contact-us">Contact Us</Link></li>
  <li><Link to="/travel-tours/customer-ticket-request">Customer Ticket Request</Link></li>
  <li><Link to="/customer-passport-issuing">Passport Issuing</Link></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
