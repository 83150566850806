// src/App.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import CustomerTicketRequest from './components/CustomerTicketRequest';
import HomePage from './components/HomePage';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import CustomerPassportIssuing from './components/CustomerPassportIssuing';

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/travel-tours/customer-ticket-request" element={<CustomerTicketRequest />} />
        <Route path="/customer-passport-issuing" element={<CustomerPassportIssuing />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
